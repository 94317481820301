<template>
  <div class="mx-5 my-5">
    <v-row>
      <v-col md="12" class="d-flex" style="justify-content: space-between; align-items: center">
        <h2>{{ invoiceTypeLabel }}</h2>

        <v-switch @change="toggleInvoiceLock" v-if="!isNew" v-model="is_locked" color="green"
          :label="$t('Invoice Lock')" :dark="$store.state.isDarkMode"></v-switch>
      </v-col>
      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('Client Name')" outlined dense color="#757575" item-text="name" item-value="id"
          v-model="invoice.client_account_id" :items="clientAccounts" :loading="clientAccountLoading"
          v-debounce="clientAc"></v-autocomplete>
      </v-col>

      <v-col md="3" class="py-0">
        <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$t('date')" :dark="$store.state.isDarkMode" outlined dense persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
              v-on="on" v-model="invoiceDate"></v-text-field>
          </template>
          <v-date-picker v-model="invoiceDate" no-title @input="dateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="3" class="py-0">
        <v-menu ref="timeMenuRef" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="invoiceTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="invoiceTime" :label="$t('Time')" :dark="$store.state.isDarkMode" prepend-icon="mdi-clock-time-four-outline" readonly
              outlined dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="timeMenu" v-model="invoiceTime" full-width
            @click:minute="$refs.timeMenuRef.save(invoiceTime)"></v-time-picker>
        </v-menu>
      </v-col>

      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('cost center')" outlined dense color="#757575" item-text="name" item-value="id"
          v-model="invoice.cost_center_id" :items="costCenters" :loading="costCenterLoading"
          v-debounce="costCenterAc"></v-autocomplete>
      </v-col>

      <v-col md="3" class="py-0">
        <v-text-field :label="$t('Invoice Number')" outlined dense v-model="invoice.serial"></v-text-field>
      </v-col>
      <v-col md="3" class="py-0" style="display: flex; justify-content: center; align-items: flex-start">
        <v-radio-group :dark="$store.state.isDarkMode" row v-model="invoice.discount_type" class="mt-0">
          <template v-slot:label><div style="font-size: 14px;">{{ $t('Discount') }}</div></template>
          <v-radio value="PERCENTAGE"><template v-slot:label><div style="font-size: 14px;">{{ $t('Percent') }}</div></template></v-radio>
          <v-radio value="FIXED"><template v-slot:label><div style="font-size: 14px;">{{ $t('Value') }}</div></template></v-radio>
        </v-radio-group>
      </v-col>

      <v-col md="3" class="py-0">
        <v-text-field :label="$t('Discount')" :dark="$store.state.isDarkMode" type="number" outlined dense v-model="invoice.discount_value"></v-text-field>
      </v-col>
      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('Inventory')" outlined dense color="#757575" item-text="name" item-value="id" no-filter
          v-model="invoice.inventory_id" :loading="inventoriesLoading" :items="inventories" return-object
          v-debounce="inventoriesAc" @input="matchMaterialInventoriesHandler"></v-autocomplete>
      </v-col>
      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('Discount Account')" outlined dense color="#757575" item-text="name" item-value="id"
          v-model="invoice.discount_account_id" :items="disscountAccounts" :loading="disscountAccountLoading"
          v-debounce="disscountAccountAc"></v-autocomplete>
      </v-col>
      <v-col md="6" class="py-0">
        <v-text-field :label="$t('notes')" v-model="invoice.notes" outlined dense></v-text-field>
      </v-col>

      <v-col md="12">
        <v-row>
          <v-col md="3" class="py-0">
            <v-autocomplete :label="$t('Payment Method')" :dark="$store.state.isDarkMode" outlined dense color="#757575" item-text="label" item-value="value"
              no-filter :items="paymentMethods" v-model="invoice.payment_method"></v-autocomplete>
          </v-col>

          <v-col md="3" class="py-0" v-if="invoice.payment_method == 'CASH'">
            <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('hr.loan.cash')" outlined dense color="#757575" item-text="name" item-value="id"
              v-model="invoice.cash_account_id" :items="cashAccounts" :loading="cashAccountLoading"
              v-debounce="cashAc"></v-autocomplete>
          </v-col>
          <v-col md="3" class="py-0" v-if="invoice.payment_method == 'CASH'">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('Paid Value')" v-model.number="invoice.paid_amount" outlined type="number"
              dense></v-text-field>
          </v-col>
        </v-row>
      </v-col>


      <v-card v-if="invoice.payment_method == 'INSTALLMENT'" md="12" class="my-5 mx-5">
        <v-card-title class="d-flex justify-center">
          <h2>
            {{ $t('Distribution Installments') }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-container id="buffet-table" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
              <tr>
                <th id="idAutoComp">{{ $t('due-date') }}</th>
                <th id="idAutoComp">{{ $t('hr.loan.trans-value') }}</th>
                <th id="idAutoComp">{{ $t('note') }}</th>
                <th></th>
              </tr>
              <v-fade-transition mode="out-in" :group="true" tag="tbody">
                <tr v-for="(el, ix) in installments" :key="ix">
                  <td>
                    <v-text-field full-width dense hide-details  type="date" flat color="#757575" v-model="el.date"
                      @keydown.enter="ToNextInput"></v-text-field>
                  </td>
                  <td>
                    <v-text-field full-width dense hide-details  type="number" flat color="#757575"
                      v-model="el.amount" @keydown.enter="ToNextInput"></v-text-field>
                  </td>
                  <td>
                    <v-text-field @keydown.enter="addNewInstallment" full-width type="text" dense hide-details  flat
                      color="#757575" v-model="el.note"></v-text-field>
                  </td>
                  <td>
                    <v-icon @click="deleteRowI(ix)" color="red"
                      style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                  </td>
                </tr>
              </v-fade-transition>
            </v-simple-table>
          </v-container>
        </v-card-text>
      </v-card>
      <v-col >
        <v-card md="12" class="my-5 mx-5">
          <v-container id="buffet-table" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
              <tr>
                <th style="padding-left: 100px;  padding-right: 100px;">{{ $t('Material') }}</th>
                <th id="idAutoComp">{{ $t('Type') }}</th>
                <th id="idPrice">{{ $t('quantity') }}</th>
                <th> {{ $t('Unit') }}</th>
                <th id="idPrice">{{ $t('price') }}</th>
                <th id="idPrice">{{ $t('sum') }}</th>
                <th id="idPrice">{{ $t('Discount') }}</th>
                <th id="idPrice">{{ $t('add') }}</th>
                <th id="idPrice">{{ $t('Net') }}</th>
                <th id="idAutoComp">{{ $t('Inventory') }}</th>
                <th  id="idAutoComp">{{ $t('Corresponding Account') }}</th>
                <th  id="idAutoComp">{{ $t('Beneficiary') }}</th>
                <th  id="idAutoComp">{{ $t('Beneficiary Percentage') }}</th>
                <th  id="idAutoComp">{{ $t('explain') }}</th>
                <th></th>
              </tr>
              <v-fade-transition mode="out-in" :group="true" tag="tbody">
                <td></td>
                <tr v-for="(material, ix) in invoice.materials" :key="ix">
            <td>
              <AutoCompleteField label="" v-model="material.material_id" endPoint="/inventories/materials/auto-complete"
                returnObject @input="onMaterialInput(material)" />
            </td>
            <td>
              <v-select :items="material.type" v-model="material.type_id" item-text="name" item-value="id">
              </v-select>
            </td>
            <td>
              <v-text-field full-width dense hide-details  flat type="number" color="#757575"
                @keydown.enter="ToNextInput" v-model.number="material.quantity"></v-text-field>
            </td>
            <td>{{ $t('Piece') }}</td>
            <td>
              <v-text-field full-width dense hide-details  type="number" flat color="#757575"
                @keydown.enter="ToNextInput" v-model.number="material.price"></v-text-field>
            </td>
            <td>{{ material.quantity * material.price }}</td>
            <td>
              <v-text-field full-width dense hide-details  type="number" flat color="#757575"
                @keydown.enter="ToNextInput" v-model.number="material.discount_value"></v-text-field>
            </td>
            <td>
              <v-text-field full-width dense hide-details  type="number" flat color="#757575"
                @keydown.enter="ToNextInput" v-model.number="material.increase_value"></v-text-field>
            </td>
            <td>
              {{
                material.quantity * material.price -
                material.discount_value +
                material.increase_value
              }}
            </td>
            <td>
              <AutoCompleteField label="" v-model="material.inventory_id" endPoint="/inventories/auto-complete"
                returnObject @input="ToNextInput" />
            </td>
            <td>
              <AutoCompleteField label="" v-model="material.account_id" endPoint="/accounting/account/auto-complete"
                returnObject @input="ToNextInput" />
            </td>
            <td>
              <AutoCompleteField label="" v-model="material.beneficiary_account_id"
                endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" clearable />
            </td>
            <td>
              <v-text-field full-width dense hide-details  type="number" flat color="#757575"
                @keydown.enter="ToNextInput" v-model.number="material.beneficiary_percentage"></v-text-field>
            </td>
            <td>
              <v-text-field full-width dense hide-details  flat color="#757575" @keydown.enter="addNewMaterial"
                v-model="material.desc"></v-text-field>
            </td>
            <td>
              <v-icon @click="deleteRow(ix)" color="red"
                style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
            </td>
          </tr>
        </v-fade-transition>
            </v-simple-table>
          </v-container>
        </v-card>

      </v-col>

      <v-col md="9">
        <v-btn @click="newExpense" class="my-1" color="green" dark>{{ $t('Add Account') }}</v-btn>
        <v-row v-if="invoice.invoice_expenses.length">
          <v-col md="3"></v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Expenses Account') }}</h3>
          </v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Corresponding Account') }}</h3>
          </v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Value') }}</h3>
          </v-col>
          <!--  -->
          <v-row class="py-0" v-for="(expense, ix) in invoice.invoice_expenses" :key="ix">
            <v-col md="3" class="pt-0">
              <v-btn color="red" dark @click="deleteInvoiceExpense(ix)">
                <v-icon size="20" dark>mdi-delete</v-icon>
                {{ $t('Delete Account') }}
              </v-btn>
            </v-col>
            <v-col md="3" class="py-0">
              <AutoCompleteField label="" v-model="expense.expense_account" endPoint="/accounting/account/auto-complete"
                outlined />
            </v-col>
            <v-col md="3" class="py-0">
              <AutoCompleteField label="" v-model="expense.related_account" endPoint="/accounting/account/auto-complete"
                outlined />
            </v-col>
            <v-col md="3" class="py-0">
              <v-text-field type="number" v-model.number="expense.value" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <!--  -->
        </v-row>
        <v-row>
          <v-col md="3"></v-col>
          <v-col md="3"></v-col>
          <v-col md="3"></v-col>
          <v-col md="3" class="pl-0" v-if="invoice.invoice_expenses.length">
            <v-text-field :value="invoice.invoice_expenses.reduce(
              (prev, curr) => prev + curr.value,
              0
            )
              " readonly outlined dense :label="$t('Total Expenses')"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="3" style="padding-top: 94px">
        <v-text-field :value="invoiceTotalValue" outlined dense :label="$t('Invoice Value')"></v-text-field>
        <v-text-field :value="totalQuantities" readonly outlined dense :label="$t('Total Quantities')"></v-text-field>
      </v-col>
      <v-col md="4">
        <v-btn color="blue" :disabled="!isNew && is_locked" block @click="createNewInvoice" :loading="submitting">{{ isNew
          ? $t('add') : $t('edit') }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import axios from "axios";
import AutoCompleteField from "../AutoCompleteField.vue";
import i18n from "../../../../../i18n";
export default {
  components: { AutoCompleteField },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      installments: [],
      is_locked: false,
      timeMenu: false,
      timeMenuRef: null,
      invoiceDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      invoiceTime: `${String(new Date().getHours()).length == 1
          ? `0${new Date().getHours()}`
          : new Date().getHours()
        }:${String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
        }`,
      invoiceTypeLabel: null,
      fetchingMetadata: false,
      invoiceMetadata: null,
      disscountType: "PERCENTAGE",
      paymentMethods: [
        {
          label: i18n.t('Cash'),
          value: "CASH",
        },
        {
          label: i18n.t('Credit'),
          value: "CREDIT",
        },
        {
          label: i18n.t('Installment'),
          value: "INSTALLMENT",
        },
      ],
      dateMenu: false,
      invoice: {
        client_account_id: null,
        date: null,
        serial: null,
        notes: null,
        discount_value: null,
        discount_type: null,
        invoice_type_id: null,
        cost_center_id: null,
        inventory_id: null,
        payment_method: null,
        cash_account_id: null,
        discount_account_id: null,
        paid_amount: null,
        is_locked: false,
        invoice_expenses: [],
        materials: [],
        total_amount: 0,
      },
      costCenterLoading: false,
      costCenters: [],
      inventoriesLoading: false,
      inventories: [],
      clientAccounts: [],
      clientAccountLoading: false,
      cashAccounts: [],
      cashAccountLoading: false,
      disscountAccounts: [],
      disscountAccountLoading: false,
      submitting: false,
      sid: null,
    };
  },
  computed: {
    totalQuantities() {
      let val = 0;

      if (this.invoice.materials.length) {
        val = this.invoice.materials.reduce(
          (prev, curr) => prev + curr.quantity,
          0
        );
      }
      return val;
    },
    invoiceTotalValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        val = this.invoice.materials.reduce(
          (prev, curr) =>
            prev +
            (curr.quantity * curr.price - curr.discount_value) +
            curr.increase_value,
          0
        );
        if (
          this.invoice.discount_type === "FIXED" &&
          this.invoice.discount_value
        ) {
          val -= this.invoice.discount_value;
        } else if (
          this.invoice.discount_type === "PERCENTAGE" &&
          this.invoice.discount_value
        ) {
          val -= val * (this.invoice.discount_value / 100);
        }
      }
      return val;
    },
  },
  methods: {
    deleteRowI(index) {
      if (this.installments.length == 1) return;
      this.installments.splice(index, 1);
    },
    addNewInstallment(focus = true) {
      this.installments.push({
        date: null,
        amount: null,
        note: null,

      });
    },
    deleteInvoiceExpense(ix) {
      this.invoice.invoice_expenses.splice(ix, 1);
    },
    async toggleInvoiceLock() {
      await axios.patch(
        `/inventories/invoices/${this.$route.params.id}/toggle-lock`,
        {
          is_locked: this.is_locked,
        }
      );
    },
    async getInvoiceTypeMetadata() {
      try {
        this.fetchingMetadata = true;
        const result = await axios.get(
          `/inventories/invoices/invoice-type/${this.$route.query.invoice_id}`
        );
        this.invoiceMetadata = result.data.data;
        this.invoice.serial = this.invoiceMetadata.next_serial;
        this.invoice.payment_method =
          this.invoiceMetadata.invoiceType.payment_method;
        this.costCenters.push(this.invoiceMetadata.invoiceType.cost_center);
        this.invoice.cost_center_id =
          this.invoiceMetadata.invoiceType.cost_center_id;
        this.inventories.push(this.invoiceMetadata.invoiceType.inventory);
        this.invoice.inventory_id = this.invoiceMetadata.invoiceType.inventory;

        this.cashAccounts.push(this.invoiceMetadata.invoiceType.cash_account);
        this.invoice.cash_account_id =
          this.invoiceMetadata.invoiceType.cash_account_id;

        this.disscountAccounts.push(
          this.invoiceMetadata.invoiceType.discount_account
        );
        this.invoice.discount_account_id =
          this.invoiceMetadata.invoiceType.discount_account_id;

        this.invoiceTypeLabel =
          this.invoiceTypeFormatter(this.invoiceMetadata.invoiceType.type) +
          " - " +
          this.invoiceMetadata.invoiceType.name;
      } finally {
        this.fetchingMetadata = false;
      }
    },
    matchMaterialInventoriesHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.inventory_id = this.invoice.inventory_id;
      });
    },
    matchMaterialRelatedAccountHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.account_id = this.invoiceMetadata.invoiceType.related_account;
      });
    },
    async createNewInvoice() {
      try {
        this.submitting = true;

        await axios.post("/inventories/invoices", {
          ...this.invoice,
          discount_type: this.invoice.discount_type,
          inventory_id: this.invoice.inventory_id?.id,
          date: `${this.invoiceDate} ${this.invoiceTime}`,
          total_amount: this.invoiceTotalValue,
          cash_account_id:
            this.invoice.payment_method == "CREDIT"
              ? null
              : this.invoice.cash_account_id,
          paid_amount:
            this.invoice.payment_method == "CREDIT"
              ? null
              : this.invoice.paid_amount,
          materials: this.invoice.materials.map((mat) => ({
            ...mat,
            inventory_id: mat.inventory_id?.id,
            material_id: mat.material_id?.id,
            type: mat.material_id?.type,
            beneficiary_account_id: mat.beneficiary_account_id?.id,
            account_id: mat.account_id?.id,
          })),
          installments: this.installments.map((mat) => ({
            ...mat,
            date: mat.date,
            amount: mat.amount,
            note: mat.note,

          })),
          student_id: this.sid,
        });

        this.$router.push("/invoice-list");
      } finally {
        this.submitting = false;
      }
    },
    invoiceTypeFormatter(type) {
      return [
        {
          label: "فاتورة مشتريات",
          value: "PURCHASES",
        },
        {
          label: "فاتورة مبيعات",
          value: "SALES",
        },
        {
          label: "فاتورة مرتجع المشتريات",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "فاتورة مرتجع المبيعات",
          value: "SALES_REFUNDS",
        },
      ].find((el) => el.value == type).label;
    },
    async cashAc(value) {
      try {
        if (!value) return;
        this.cashAccountLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.cashAccounts = searchResult.data.data;
      } catch (err) {
      } finally {
        this.cashAccountLoading = false;
      }
    },
    async clientAc(value) {
      try {
        if (!value) return;
        this.clientAccountLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.clientAccounts = searchResult.data.data;
      } catch (err) {
      } finally {
        this.clientAccountLoading = false;
      }
    },
    async inventoriesAc(value) {
      if (!value) return;
      try {
        this.inventoriesLoading = true;
        const searchResult = await axios.get("/inventories/auto-complete", {
          params: {
            query: value,
          },
        });
        this.inventories = searchResult.data.data;
      } catch (err) {
      } finally {
        this.inventoriesLoading = false;
      }
    },
    async costCenterAc(value) {
      try {
        if (!value) return;
        this.costCenterLoading = true;
        const searchResult = await axios.get(
          "accounting/cost-center/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.costCenters = searchResult.data.data;
      } catch (err) {
      } finally {
        this.costCenterLoading = false;
      }
    },
    async disscountAccountAc(value) {
      try {
        if (!value) return;
        this.disscountAccountLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.disscountAccounts = searchResult.data.data;
      } catch (err) {
      } finally {
        this.disscountAccountLoading = false;
      }
    },
    newExpense() {
      this.invoice.invoice_expenses.push({
        expense_account_id: null,
        related_account_id: null,
        value: null,
      });
    },
    deleteRow(index) {
      if (this.invoice.materials.length == 1) return;
      this.invoice.materials.splice(index, 1);
    },
    async onMaterialInput(material) {
      this.ToNextInput();
      console.log(material, "material");
      const result = await axios.get(
        `/inventories/materials/${material.material_id.id}?invoice_type=${this.invoice.invoice_type_id}`
      );
      const resultMetadata = await axios.get(
        `/inventories/invoices/invoice-type/${this.invoice.invoice_type_id}`
      );
      console.log(resultMetadata, "META");
      const metadataTemp = resultMetadata.data.data.invoiceType;

      const dataTemp = result.data.data;
      const invoiceType = resultMetadata.data.data.invoiceType.type;

      if (invoiceType == "PURCHASES") {
        if (dataTemp.purchases_account) {
          material.account_id = dataTemp.purchases_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else if (invoiceType == "SALES") {
        if (dataTemp.sales_account) {
          material.account_id = dataTemp.sales_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else if (invoiceType == "PURCHASE_REFUNDS") {
        if (dataTemp.purchases_refund_account) {
          material.account_id = dataTemp.purchases_refund_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      }
      // SALES_REFUNDS
      else {
        if (dataTemp.sales_account) {
          material.account_id = dataTemp.sales_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      }
      material.type = dataTemp.values;
      material.price = dataTemp.price;
    },
    addNewMaterial(focus = true) {
      this.invoice.materials.push({
        material_id: null,
        type: null,
        type_id: null,
        quantity: null,
        price: null,
        discount_value: null,
        increase_value: null,
        account_id: null,
        beneficiary_account_id: null,
        beneficiary_percentage: null,
        desc: null,
        inventory_id: null,
      });
      this.matchMaterialInventoriesHandler();
      this.matchMaterialRelatedAccountHandler();
      if (focus) {
        setTimeout(() => {
          this.ToNextInput();
        }, 0);
      }
    },
    ToNextInput() {
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll("table input:not([type=hidden])")
      );
      const currInput = document.activeElement;
      const currInputIndex = inputs.indexOf(currInput);
      const nextinputIndex = currInputIndex + 1;
      const input = inputs[nextinputIndex];
      input.focus();
    },
  },
  async created() {
    if (this.$route.params.sid) {
      console.log(this.$route.params.sid);
      this.sid = this.$route.params.sid;
    }
    if (!this.installments) {
      this.addNewInstallment();
    }
    if (!this.$route.params.id) {
      const result = await axios.get(
        `/inventories/invoice-template/student/${this.$route.params.sid}`
      );
      const dataTemp = result.data.data;
      this.clientAccounts.push(dataTemp.client_account)
      this.invoice.client_account_id = dataTemp.client_account_id;
      this.invoice.invoice_expenses = dataTemp.invoice_expenses.map((el) => ({
        expense_account: el.expense_account,
        related_account: el.related_account,
        expense_account_id: el.expense_account.id,
        related_account_id: el.related_account.id,
        value: el.value,
      }));
      this.invoice.materials = dataTemp.invoice_materials.map((el) => ({
        ...el,
        material_id: el.material,
        inventory_id: el.inventory,
        account_id: el.account,
        beneficiary_account_id: el.beneficiary_account,
      }));
      this.installments = dataTemp.installments.map((el) => ({
        ...el,
        date: el.date,
        amount: el.amount,
        note: el.note,
      }));
      this.invoice.discount_type = dataTemp.discount_type;
      this.invoice.discount_value = dataTemp.discount_value;
      this.invoice.invoice_type_id = dataTemp.invoice_type_id;
      this.invoice.serial = dataTemp.serial;
      this.invoice.paid_amount = dataTemp.paid_amount;
      this.invoice.payment_method = dataTemp.payment_method;
      this.costCenters.push(dataTemp.cost_center);
      this.invoice.cost_center_id = dataTemp.cost_center_id;
      this.inventories.push(dataTemp.inventory);
      this.invoice.inventory_id = dataTemp.inventory;

      this.cashAccounts.push(dataTemp.cash_account);
      this.invoice.cash_account_id = dataTemp.cash_account_id;

      this.disscountAccounts.push(dataTemp.discount_account);
      this.invoice.discount_account_id = dataTemp.discount_account_id;

      this.invoiceTypeLabel =
        this.invoiceTypeFormatter(dataTemp.invoice_type.type) +
        " - " +
        dataTemp.invoice_type.name;
      console.log(dataTemp);
      console.log(this.invoice);
    } else {
      const result = await axios.get(
        `/inventories/invoice-template/${this.$route.params.id}`
      );
      const dataTemp = result.data.data;


      this.invoice.invoice_expenses = dataTemp.invoice_expenses.map((el) => ({
        expense_account: el.expense_account,
        related_account: el.related_account,
        expense_account_id: el.expense_account.id,
        related_account_id: el.related_account.id,
        value: el.value,
      }));
      this.invoice.materials = dataTemp.invoice_materials.map((el) => ({
        ...el,
        material_id: el.material,
        inventory_id: el.inventory,
        account_id: el.account,
        beneficiary_account_id: el.beneficiary_account,
      }));
      this.installments = dataTemp.installments.map((el) => ({
        ...el,
        date: el.date,
        amount: el.amount,
        note: el.note,
      }));
      this.invoice.discount_type = dataTemp.discount_type;
      this.invoice.discount_value = dataTemp.discount_value;
      this.invoice.invoice_type_id = dataTemp.invoice_type_id;
      this.invoice.serial = dataTemp.serial;
      this.invoice.paid_amount = dataTemp.paid_amount;
      this.invoice.payment_method = dataTemp.payment_method;
      this.costCenters.push(dataTemp.cost_center);
      this.invoice.cost_center_id = dataTemp.cost_center_id;
      this.inventories.push(dataTemp.inventory);
      this.invoice.inventory_id = dataTemp.inventory;

      this.cashAccounts.push(dataTemp.cash_account);
      this.invoice.cash_account_id = dataTemp.cash_account_id;

      this.disscountAccounts.push(dataTemp.discount_account);
      this.invoice.discount_account_id = dataTemp.discount_account_id;

      this.invoiceTypeLabel =
        this.invoiceTypeFormatter(dataTemp.invoice_type.type) +
        " - " +
        dataTemp.invoice_type.name;
      console.log(dataTemp);
      console.log(this.invoice);
    }



  },


};
</script>
  
<style scoped lang="scss">
table {
  border-collapse: collapse;
  table-layout: fixed;
}

.tableId {
  border-radius: 10%;
}

td,
th {
  padding: 0px 10px;
  border-collapse: collapse;
  border-radius: 10%;
}

table,
th,
td {
  border: 1px solid #000;
  border-collapse: collapse;
  border-radius: 10%;
}

#idAutoComp {
  padding-left: 70px;
  padding-right: 70px;
}

#idPrice {
  padding-left: 40px;
  padding-right: 40px;
}

#buffet-table {
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
}

#buffet-table th {
  color: #909399;
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
  text-align: center;
  width: max-content;

}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
  width: max-content;
}

.columnSelector:hover {
  border-right: 2px solid blue;
}
</style>
